import { render, staticRenderFns } from "./NyGlobal.vue?vue&type=template&id=2f2a5562&scoped=true&"
import script from "./NyGlobal.vue?vue&type=script&lang=js&"
export * from "./NyGlobal.vue?vue&type=script&lang=js&"
import style0 from "./NyGlobal.vue?vue&type=style&index=0&id=2f2a5562&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2a5562",
  null
  
)

export default component.exports